import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import ReactHtmlParser  from 'react-html-parser';
export default class Footer extends Component {
    render() {
        return (
            <div>
                  
            </div>
        )
    }
}
